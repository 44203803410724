<template>
<v-container>

  <v-row dense >

    <v-card-title primary-title class="text-xs-body-2">
        Confirmar Pagos
    </v-card-title>

    <v-spacer></v-spacer>
    <v-toolbar dense rounded="lg" dark color="success">

      <!-- <v-card-title dense class="ma-0 py-0">Confirmar Pago a Pedido</v-card-title>  -->

      <v-spacer></v-spacer>
      <v-btn small class="mx-3" color="info" @click="regresar"><v-icon>reply</v-icon></v-btn>
      <v-btn small color="primary" @click="saveinfo">Procesar</v-btn>

    </v-toolbar>
  </v-row>

  <v-row justify-center>
    <v-col cols="12" sm="6" xm="12">
      <v-card class="elevation-3">

        <v-card-title >Datos Fiscales del Cliente</v-card-title>

        <v-card-text >
          <div><strong>{{ Clientes.nomcli }}</strong></div>
          <div><strong>{{ Clientes.Rfc }}</strong></div>
          <div>{{ Clientes.Calle }}  {{Clientes.Numext}}. {{Clientes.Numint}} {{Clientes.colonia}} </div>
          <div>{{ Clientes.Ciudad }}  {{Clientes.Estado}} {{Clientes.Cp}}  </div>
          <div>Atención :{{ Clientes.atvent}}  </div>
            <div> Email :{{Clientes.email1}} </div>
          <div>{{ Clientes.telefono}}</div>
        </v-card-text>
      </v-card>
    </v-col>

      <v-col cols="12" md="6" sm="6" xs="12" >

        <v-card class="elevation-3" >
          <v-card-title class="ma-0 py-0">Importe del Pedido</v-card-title>
          <v-card-text>
              <table width="100%">
                <tr>
                  <th style="text-align:left;">IMPORTE: </th>
                  <th style="text-align:right;">{{ getTotales.importe}}</th>
                </tr>
                <tr>
                  <th style="text-align:left;">DESCUENTO: </th>
                  <th style="text-align:right;">{{ getTotales.descuento}}</th>
                </tr>

                <tr>
                  <th style="text-align:left;">SUBTOTAL: </th>
                  <th style="text-align:right;">{{ getTotales.subtotal}}</th>
                </tr>
                <tr>
                  <th style="text-align:left;">IVA: </th>
                  <th style="text-align:right;">{{ getTotales.iva}}</th>
                </tr>
                <tr>
                  <th style="text-align:left;">TOTAL: </th>
                  <th style="text-align:right;">{{ getTotales.total}}</th>
                </tr>
            </table>

          </v-card-text>

        </v-card>

      </v-col>

    </v-row>

    <v-row >
      <v-col cols="12" md="6" sm="6" xs="12" class="ma-0 py-0">
        <v-card class="elevation-3" >

          <v-card-title v-if="Recogeentienda" class="ma-0 py-0">Recoge en Tienda</v-card-title>

          <v-card-title v-else class="ma-0 py-0">Dirección de Envío</v-card-title>

          <v-card-text  class="pa-0 ma-0" v-if="mismaDireccion">
            <div>Atención: {{ Clientes.atvent}}   </div>
            <div>{{ Clientes.Calle }}  {{Clientes.Numext}}. {{Clientes.Numint}} {{Clientes.colonia}} </div>
            <div>{{ Clientes.Ciudad }}  {{Clientes.Estado}} {{Clientes.Cp}}  </div>
            <div>{{ Clientes.telefono}}</div>
          </v-card-text>

          <v-card-text class="ma-2 py-2" v-else>
            <div>{{ Direnvio.Nombre}}</div>
            <div>Atención: {{ Direnvio.encargado}}   </div>
            <div>{{ Direnvio.Calle }}  {{Direnvio.Numext}}. {{Direnvio.Numint}} {{Direnvio.colonia}} </div>
            <div>{{ Direnvio.Ciudad }}  {{Direnvio.Estado}} {{Direnvio.Cp}}  </div>
            <div>{{ Direnvio.telefono}}</div>

          </v-card-text>

        </v-card>
      </v-col>

      <v-col cols="12"  xl="6" lg="6" md="6" sm="6" xs="12"  class="ma-0 py-0">
        <v-card class="elevation-3" >
          <v-card-text >
            <div class="bue--text" >Fecha: {{newFecha}}</div>
            <!-- <h2 class="red--text" >Cotización: {{docant.Numdoc}}</h2> -->

            <p>
              Realiza tu pago en tiendas de conveniencia.
            </p>
            <v-img
              class="ma-1"
              contain
              max-height="50"
              :src= "src">

            </v-img>

              <!-- PAGO POR TRANSFERENCIA -->

                <v-card-text>
                  <div>Datos para Depósito</div>

                  <v-textarea
                    auto-grow
                    outlined
                    v-model="cuentas"
                  ></v-textarea>

                </v-card-text>

          </v-card-text>
        </v-card>
      </v-col>

      <!-- PAGO POR TRANSFERENCIA -->
      <v-col cols="12" v-if="tipopago == 3" xl="4" lg="4" md="5" sm="5" xs="8">
        <v-card class="elevation-0" >
          <v-card-text>
            <div>Datos para transferencia</div>
            <div>Banco: Banorte</div>
            <div>Cuenta: 2050648097618594689275 </div>
            <div>Clabe: 2050648097618594689275</div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- LINEA DE CREDITO    -->
      <v-col cols="12" v-if="tipopago == 4" xl="4" lg="4" md="5" sm="5" xs="8">
        <v-card class="elevation-0">
          <v-card-text v-if="credito == false">
            <!-- <div style="font-size: 18px;"><strong>{{ mensaje }}</strong></div> -->
          </v-card-text>
          <v-card-text v-else>
            <div style="font-size: 16px;">Días de crédito:  {{ dias }}</div>
            <div style="font-size: 16px;">Saldo disponible: {{ saldo }}</div>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

  <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      top
      color="success"
    >
      {{text}}
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
var base64 = require('base-64')
var accounting = require('accounting')

export default {
  data () {
    return {
      cuentas: '',
      src: '',
      tokenSait: '',
      email1: '',
      email2: '',
      Recogeentienda: false,
      tipopago: '',
      mismaDireccion: true,
      Direnvio: '',
      docant: '',
      Clientes: '',
      credito: false,
      mensaje: '',
      direccion: '',
      totales: {},
      fecha: new Date().toISOString().substr(0, 10),
      saldo: 0,
      dias: 0,
      snackbar: false,
      text: '',
      iddocum: 0,
      logourl: ''
    }
  },

  computed: {
    ...mapGetters('Login', ['getClientes', 'getdatosUsuario']),
    ...mapGetters('documento', ['getDocumento', 'getTotales']),
    ...mapGetters('tema', ['getLogourl']),

    newFecha (fecha) {
      return fecha ? this.$moment(fecha).format('DD-MMM-YYYY') : ''
    }

  },

  created () {
    this.logourl = this.getLogourl
    console.log('Params', this.$route.params)
    this.tipopago = this.$route.params.tipo
    // console.log(typeof(this.tipopago))

    // LOGO DEL PAGO
    this.src = this.$route.params.src


    this.validaCredito()

    this.Iddocum = this.getDocumento.iddocum
    console.log(this.getDocumento)

    // this.tipoPago = this.$route.params
    this.Clientes = this.getClientes
    // console.log("clientes", this.getClientes)

    this.$http.get('api/v1/order.info/' + this.Iddocum).then(response => {
      this.docant = response.body
      console.log('Doumento', this.docant)

      if (this.docant.numclisuc == '') {
        this.mismaDireccion = true
      } else {
        this.mismaDireccion = false
        this.Direnvio = this.docant.Direnvio

        var cNumclisuc = this.docant.numclisuc.trim()

        this.$http.get('auth/api/v1/getnumclisuc/' + cNumclisuc).then(response => {
          console.log('numclisuc estado', response)
          // this.idweb= response.data.idweb
          // this.nombresuc = response.data.nombre
          this.Direnvio = response.data
        })
      }

      if (this.docant.Direnvio.trim() != '' && this.docant.Numclisuc != '') {
        this.Recogeentienda = false
      } else {
        this.Recogeentienda = true
      }
    }).catch(err => { console.log(err) })

    this.$http.get('auth/api/v1/formatos').then(respuesta => {
      this.cuentas = respuesta.body.Ctadeps
      this.condicom = respuesta.body.Condicom
    }).catch(err => { console.log(err) })

    // PARA QUE ES ESTA API? por que no se corrige el original.
    this.$http.get('api/v1/email.list').then(response => {
      // console.log(response)
      this.email1 = response.body.Email1
      this.email2 = response.body.Email2
    }).catch(err => { console.log(err) })

    // LOGIN DE API SAITNUBE
    // var url = 'https://sait-md3.saitnube.com/api/v1/login'
    // var Payload = {
    //  "numuser": "1",
    //  "password": "123"
    // }

    //  //API AXIOS SAIT NUBE
    // var url = process.env.VUE_APP_SAIT_NUBE
    // var uri = url + '/api/v1/login'
    // //var url = 'https://sait-md3.saitnube.com/api/v1/login'
    // console.log(uri)
    // console.log("uri", uri, Payload)

    // axios.post( uri , Payload ,{ headers: {'Content-Type': 'application/json' }}).then(response =>  {
    //  // console.log("Sait NUBE",response.data)
    //  this.tokenSait = response.data.token
    //  console.log(this.tokenSait)
    //  localStorage.setItem("tokensait", this.tokenSait)
    // }).catch(error => {console.log(error) })
  },

  methods: {

    regresar () {
      this.$router.back()
    },
    horaSait () {
      var f = new Date()
      var hora = f.getHours()
      var minuto = f.getMinutes()
      var segundo = f.getSeconds()
      if (hora < 10) {
        hora = '0' + f.getHours()
      }
      if (minuto < 10) {
        minuto = '0' + f.getMinutes()
      }
      if (segundo < 10) {
        segundo = '0' + f.getSeconds()
      }
      return hora + ':' + minuto + ':' + segundo
    },

    enviarCorreo (documento1) {
      console.log('this.docant', this.docant)

      var correo = ''
      if (this.getdatosUsuario.numcli) {
        correo = this.getdatosUsuario.Email
      } else {
        correo = this.CargaCliente.email1
      }

      if (this.$store.state.tipomoneda == 'P') {
        this.moneda = 'MXN'
      } else {
        this.moneda = 'USD'
      }

      console.log('documento1.numdoc', documento1[0].numdoc)
      // La respuesta de order.add incluye el iddocum del generado
      var iddocum = base64.encode(documento1[0].iddocum)
      // var iddocum = base64.encode(param[0].iddocum);

      var url = this.$http.options.root.replace('tienda3', 'consultacoti/')

      this.CargaCliente = this.getClientes
      // console.log(this.CargaCliente)
      // console.log('correo', correo)

      var payload = {
        email: correo,
        subject: 'Pedido Tienda en Linea ',
        info1: 'Pedido',
        folio: documento1[0].numdoc.trim(),
        info3: 'Se ha elaborado un pedido con el folio: ',
        nombre: this.getdatosUsuario.Nomuser,
        nomuser: this.getdatosUsuario.Nomuser,
        
        direccion: '',
        // fecha: this.fecha,
        fecha: new Intl.DateTimeFormat("es").format(this.Fecha),
          
        telefono: this.getdatosUsuario.Telefono,
        divisa: this.moneda,

        rfc: this.CargaCliente.Rfc,

        subtotal: this.getTotales.subtotal,
        iva: this.getTotales.iva,
        total: this.getTotales.total,

        c2a_link: url + iddocum,
        hora: this.horaSait(),
        logo: this.logourl,

        color: this.$vuetify.theme.themes.light.secondary,
        email1: this.email1,
        email2: this.email2,
        numdoc: documento1[0].numdoc.trim(),
        layout: "pedido.html",
        nomcli: this.getClientes.nomcli,
      }

      console.log(payload)
      // // Enviar el correo
      // this.$http.post('auth/api/v1/sendcot', payload).then(response => {
      //console.log('enviando Correo')
      //   // var doc = {doc: iddocum}
      //   // this.$router.push({name:'documento', params: {info: doc}})
      // }).catch(error => { console.log(error) })

      this.$http.post('auth/api/v1/sendmail6', payload).then(response => {
        console.log('enviando Correo sendmail6', response)
        // var doc = {doc: iddocum}
        // this.$router.push({name:'documento', params: {info: doc}})
      }).catch(error => { console.log(error) })

    },

    saveinfo () {

      console.log('documento', this.docant)
      var documento = this.docant
      documento.status = 9

      var payload = {
        status: 9,
        Tipodoc: this.docant.Tipodoc,
        Numdoc: this.docant.Numdoc
      }
      // console.log(payload)
      // actualizar el estatus
      this.$http.put('auth/api/v1/updatestatus', payload).then(response => {
        // console.log(response)
      }).catch(error => { console.log(error) })

      /// ///////////////////////////////////
      // Agregarmos el pedido de esa cotización

      documento.Direnvio = documento.Direnvio
      documento.Tipodoc = ' P'
      documento.Fechapago = this.fecha
      documento.Fecha = this.fecha
      documento.Refer = documento.Numdoc
      documento.status = 0
      documento.statusweb = 2

      var HoraPed = this.horaSait()

      // console.log('Documento a convertir a Pedido', documento)

      this.$http.post('api/v1/order.add', documento).then(response => {
        this.text = 'Pedido generado correctamente'
        this.snackbar = true
        // console.log("order add", response.body)

        this.enviarCorreo(response.body)
        setTimeout(() => { this.$router.push({ name: 'cotizaciones' }) }, 3000)
      }).catch(error => {
        console.log(error)
      })

      // console.log("DOCUMENTO", documento)

      var items = []
      var i = 1

      documento.Movim.forEach((element) => {
        items.push({
          tipodoc: ' P',
          numdoc: 'A1',
          numpar: i,
          numart: element.Numart,
          desc: 'Manuel',
          pend: element.Cant,
          pjedesc: element.Pjedesc,
          cant: element.Cant,
          unidad: element.Unidad,
          impuesto1: element.Impuesto1,
          impuesto2: 0,
          precio: parseFloat(element.Precio),
          obs: ''
        })
        i = i + 1
      })

      // console.log(items)
      // GRabar pedido en Sait.
      console.log(documento)
      var Pedidodoc = {
        tipodoc: documento.Tipodoc,
        numdoc: 'P55',
        numuser: '',
        nunvend: '',

        fecha: documento.Fecha,
        fentrega: documento.Fecha,
        fechacapt: documento.Fecha,

        divisa: documento.divisa,

        hora: HoraPed,
        hentrega: '',

        status: 1,
        formapago: '1',
        numalm: documento.Numalm,
        impuesto1: documento.impuesto1,
        impuesto2: 0,
        descuento: documento.descuento,
        importe: documento.Importe,
        numcli: documento.Numcli,
        pjedesc: 0,
        nomcli: '',
        direnvio: documento.Direnvio,
        tc: documento.Tc,
        obs: documento.Obs,
        items: items
      }

      console.log('Checar IVA PEDIDO DOC', Pedidodoc)
      console.log('TokenSait', this.tokenSait)

      // API AXIOS SAIT NUBE
      // var url = 'https://sait-md3.saitnube.com/api/v1/pedidos'
      //  axios.post(url, Pedidodoc,{ headers: {'Content-Type': 'application/json', 'x-token': this.tokenSait }}).then(response =>  {
      //     console.log("Sait NUBE",response.data)
      //   }).catch(error => {console.log(error) })
    },

    validaCredito () {
      if (this.getClientes.diascred == undefined) {
        this.credito = false
        this.mensaje = 'No cuentas con línea de credito'
      } else {
        this.credito = true
        this.saldo = accounting.formatNumber(this.getClientes.saldo, 2)
        this.dias = this.getClientes.diascred
      }
    }

  }

}
</script>
